import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { setAngularJSGlobal } from "@angular/upgrade/static";

import { AppModule } from "./app/app.module";

import { WalkMe } from "./app/core/init/walkme";

import angular from "angular";
import "angular-translate";
import "angular-i18n/angular-locale_en-us";
import { ApplicationInitEvent } from "./app/core/diagnostic/event";

setAngularJSGlobal(angular);

function initialize(instance: AppModule) {
  const origin = instance.window.origin();
  instance.event.log(new ApplicationInitEvent(origin.time, origin.navigation));

  import("@legacy/nationwide/app/app").then(() => {
    instance.upgrade.bootstrap(
      document.documentElement,
      ["nationwide.quoteandbind.app"],
      {
        strictDi: true,
      },
    );
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    const instance = ref.instance;
    instance.auth.login().subscribe((identity) => {
      if (identity) {
        WalkMe.init(instance.config.app.walkMeUrl);
      }

      initialize(instance);
    });
  })
  .catch((err) => console.error(err));
